
import './nav.css';
import './home.css';
import './services.css';
import './about.css';
import './price.css';
import './contact.css';
import './footer.css';

import { useEffect, useRef } from 'react';
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import CountUp from 'react-countup';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import emailjs from '@emailjs/browser';


import bulle from './img/bulle.png';

import office from './img/office.jpg';
import hotel from './img/hotel.jpg';
import pub from './img/pub.jpg';
import cuisine from './img/cuisine.jpg';

import birm from './img/birm.jpg';

import fitz from './img/fitzgerald.jpg';
import elliot from './img/elliott.svg';
import bouygues from './img/bouygues.png';
import cpc from './img/cpc.png';



function App() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_17ogtjf', 'template_0jn6ig8', form.current, {
        publicKey: '8UiSkPV3j34Nt_wQa',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      alert('Email sent !');
      form.current.reset();
  };


  return (
    <div className="App">

      <div className='header'>
          <div className='nav'>
            <p className='logo'>Trust Cleaning</p>
            <ul className='liste_nav'>
              <a href="#services">Services</a>
              <a href="#about">About</a>
              <a href="#price">Pricing</a>
              <a href="#contact">Contact</a>
            </ul>
          </div>
      </div>

      <div id="home" className='home'>
        <Parallax speed={-10}>
          <img className='bulle' id='bulle1' src={bulle}></img>
        </Parallax>
        <Parallax speed={-5}>
          <img className='bulle' id='bulle2' src={bulle}></img>
        </Parallax>
        <Parallax speed={5}>
          <img className='bulle' id='bulle3' src={bulle}></img>
        </Parallax>
        <Parallax speed={10}>
          <img className='bulle' id='bulle4' src={bulle}></img>
        </Parallax>
        <Parallax speed={2}>
          <img className='bulle' id='bulle5' src={bulle}></img>
        </Parallax>
        <Parallax speed={-8}>
          <img className='bulle' id='bulle6' src={bulle}></img>
        </Parallax>
        <Parallax speed={4}>
          <img className='bulle' id='bulle7' src={bulle}></img>
        </Parallax>
        
        <p className='name'>Trust Cleaning</p>
        <i><p className='slogan'>- where cleanliness meets trust -</p></i>
        <p className='slogan2'>Professional Cleaning Services in the West Midlands</p>


        <div className='tel'>
          <button>
            <span class="button_top">TEL : 0782 482 5515</span>
          </button>
        </div>

      </div>


      <div id='services' className='services'>
        <h1>Services</h1>

        <div className='service'>
          <div className='office'>
            <p>Office Cleaning</p>
            <div className='image'><img src={office}/></div>
          </div>
          <div className='hotel'>
            <p>Hotel Cleaning</p>
            <div className='image'><img src={hotel}/></div>
          </div>
          <div className='pub'>
            <p>Pub Cleaning</p>
            <div className='image'><img src={pub}/></div>
          </div>
          <div className='cuisine'>
            <p>Home Cleaning</p>
            <div className='image'><img src={cuisine}/></div>
          </div>
        </div>

        <div className='service_txt'>
          <p>We do all sorts of cleaning : Deep cleaning, One off cleaning, Everyday cleaning, ... Interior and exterior for both <b>commercial organisations</b> and <b>residential customers</b>.</p>
        </div>
        <div className='service_txt'>
          <p>Many other cleaning services :</p>
        </div>
      </div>


      <div id='about' className='about'>
        <h1>About us</h1>
        <div className='expli'>
          <p>Based in <b>Birmingham</b>, Trust Cleaning was funded by <b>Rachad Charles Badarou</b> in 2004. With a dedicated team of around 10 employees, we prioritize quality, reliability, 
            and customer satisfaction above all else. From residential to commercial cleaning services, we utilize the latest tools and eco-friendly products to ensure your space 
            is left spotless. </p>
            <div className='img_birm'>
              <ParallaxBanner style={{ aspectRatio: '1.5 / 1' }}>
                <ParallaxBannerLayer image={birm} speed={-10} />
              </ParallaxBanner> 
            </div>
            
        </div>
        <div className='stats'>
          <div className='stat1'>
            <p className='numero'><CountUp end={20} duration={3} enableScrollSpy/></p>
            <p className='stat_titre'>Years Experience</p>
          </div>
          <div className='stat1'>
            <p className='numero'><CountUp end={10} duration={2} enableScrollSpy/></p>
            <p className='stat_titre'>Team Members</p>
          </div>
          <div className='stat1'>
            <p className='numero'><CountUp end={100} duration={4} enableScrollSpy/>+</p>
            <p className='stat_titre'>Satisfied Clients</p>
          </div>
        </div>
        <div className='trust'>
          <div className='trust_txt'>
            <p>They trusted us :</p>
          </div>
          
          <div class="slider">
            <Splide
              options={{
                type: "loop",
                gap: "0px",
                drag: "free",
                arrows: false,
                pagination: false,
                perPage: 4,
                breakpoints: {
                  431: {
                    perPage: 2,
                  }
                  },
                autoScroll: {
                  pauseOnHover: false,
                  pauseOnFocus: false,
                  rewind: false,
                  speed: -0.5
                }
              }}
              extensions={{ AutoScroll }}
            >
              <SplideSlide>
                  <img src={fitz} alt="Image 1" />
              </SplideSlide>
              <SplideSlide>
                <img src={elliot} alt="Image 2" />
              </SplideSlide> 
              <SplideSlide>
                <img src={bouygues} alt="Image 3" />
              </SplideSlide>  
              <SplideSlide>
                <img src={cpc} alt="Image 4" />
              </SplideSlide>    
              </Splide>
          </div>
        </div>
      </div>


      <div id='price' className='price'>
        <h1>Pricing</h1>
        <div className='prix'>
          <div className='card'>
            <p className='prix_titre'>Minimum price for standard cleaning :</p>
            <p className='prix_prix'>£18 per hour</p>
            <p className='add'>Additional fees may be added depending on certain conditions.</p>
          </div>
        </div>
      </div>


      <div id='contact' className='contact'>
        <h1>Contact</h1>
        <div className='contacts'>
          <button>
            <span class="button_top">Tel : 0782 482 5515</span>
          </button>
          <button>
            <a href="mailto: trustcleaning04@yahoo.com" class="button_top mail">Email : trustcleaning04@yahoo.com</a>
          </button>
        </div>
        

        <div class="container">
          <div class="form_area">
              <p class="title">ANY QUESTIONS ?</p>
              <form ref={form} onSubmit={sendEmail}>
                  <div class="form_group">
                      <label class="sub_title" for="name" >Name</label>
                      <input placeholder="Enter your full name" class="form_style" type="text" name="user_name" required/>
                  </div>
                  <div class="form_group">
                      <label class="sub_title" for="email">Email</label>
                      <input placeholder="Enter your email" id="email" class="form_style" type="email" name="user_email" required/>
                  </div>
                  <div class="form_group">
                      <label class="sub_title" for="password">Message</label>
                      <textarea id="message" placeholder="Your message" class="form_style" name="message" required/>
                  </div>
                  <input className='btn' type="submit" value="SEND"/>
              </form></div><a class="link" href=""></a>
          </div>

      </div>

      <div className='footer'>
        <p className='logo2'>Trust Cleaning</p>
        <p>© Trust Cleaning. 2024. All Rights Reserved</p>
        <p>47 Kitswell Gardens, Bartley Green, B32 4DZ, Birmingham (UK)</p>
        <p>Tel : 0782 482 5515</p>
      </div>
    </div>
  );
}

export default App;
